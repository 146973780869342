<template>
  <div class="mt-8">
    <Table
      v-if="!isXs"
      :loading="loading"
      :columns="columns"
      :data="datas"
      border
      height="500"
    >
      <template
        slot="wsaction1"
        slot-scope="{ row }"
      >
        <div v-if="row.wsranking1 === 1">
          <img
            :src="require('../../../assets/top1.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.wsranking1 === 2">
          <img
            :src="require('../../../assets/top2.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.wsranking1 === 3">
          <img
            :src="require('../../../assets/top3.png')"
            alt=""
          >
        </div>
        <div
          v-else-if="row.wsname1"
          class="mclast"
        >
          <span>{{ row.wsranking1 }}</span>
          <img
            :src="require('../../../assets/top4.png')"
            alt=""
          >
        </div>
      </template>
      <template
        slot="wsaction2"
        slot-scope="{ row }"
      >
        <div v-if="row.wsranking2 === 1">
          <img
            :src="require('../../../assets/top1.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.wsranking2 === 2">
          <img
            :src="require('../../../assets/top2.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.wsranking2 === 3">
          <img
            :src="require('../../../assets/top3.png')"
            alt=""
          >
        </div>
        <div
          v-else-if="row.wsname1"
          class="mclast"
        >
          <span>{{ row.wsranking2 }}</span>
          <img
            :src="require('../../../assets/top4.png')"
            alt=""
          >
        </div>
      </template>
      <template
        slot="tjaction1"
        slot-scope="{ row }"
      >
        <div v-if="row.tjranking1 === 1">
          <img
            :src="require('../../../assets/top1.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.tjranking1 === 2">
          <img
            :src="require('../../../assets/top2.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.tjranking1 === 3">
          <img
            :src="require('../../../assets/top3.png')"
            alt=""
          >
        </div>
        <div
          v-else-if="row.tjname1"
          class="mclast"
        >
          <span>{{ row.tjranking1 }}</span>
          <img
            :src="require('../../../assets/top4.png')"
            alt=""
          >
        </div>
      </template>
      <template
        slot="tjaction2"
        slot-scope="{ row }"
      >
        <div v-if="row.tjranking2 === 1">
          <img
            :src="require('../../../assets/top1.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.tjranking2 === 2">
          <img
            :src="require('../../../assets/top2.png')"
            alt=""
          >
        </div>
        <div v-else-if="row.tjranking2 === 3">
          <img
            :src="require('../../../assets/top3.png')"
            alt=""
          >
        </div>
        <div
          v-else-if="row.tjname2"
          class="mclast"
        >
          <span>{{ row.tjranking2 }}</span>
          <img
            :src="require('../../../assets/top4.png')"
            alt=""
          >
        </div>
      </template>
    </Table>
    <div
      v-else
    >
      <Table
        :loading="loading"
        :columns="columns1"
        :data="datas"
        height="500"
      >
        <template
          slot="wsaction1"
          slot-scope="{ row }"
        >
          <div v-if="row.wsranking1 === 1">
            <img
              :src="require('../../../assets/top1.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.wsranking1 === 2">
            <img
              :src="require('../../../assets/top2.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.wsranking1 === 3">
            <img
              :src="require('../../../assets/top3.png')"
              alt=""
            >
          </div>
          <div
            v-else-if="row.wsname1"
            class="mclast"
          >
            <span>{{ row.wsranking1 }}</span>
            <img
              :src="require('../../../assets/top4.png')"
              alt=""
            >
          </div>
        </template>
        <template
          slot="wsaction2"
          slot-scope="{ row }"
        >
          <div v-if="row.wsranking2 === 1">
            <img
              :src="require('../../../assets/top1.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.wsranking2 === 2">
            <img
              :src="require('../../../assets/top2.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.wsranking2 === 3">
            <img
              :src="require('../../../assets/top3.png')"
              alt=""
            >
          </div>
          <div
            v-else-if="row.wsname1"
            class="mclast"
          >
            <span>{{ row.wsranking2 }}</span>
            <img
              :src="require('../../../assets/top4.png')"
              alt=""
            >
          </div>
        </template>
      </Table>

      <Table
        :loading="loading"
        :columns="columns2"
        :data="datas"
        height="500"
        class="mt-4"
      >
        <template
          slot="tjaction1"
          slot-scope="{ row }"
        >
          <div v-if="row.tjranking1 === 1">
            <img
              :src="require('../../../assets/top1.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.tjranking1 === 2">
            <img
              :src="require('../../../assets/top2.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.tjranking1 === 3">
            <img
              :src="require('../../../assets/top3.png')"
              alt=""
            >
          </div>
          <div
            v-else-if="row.tjname1"
            class="mclast"
          >
            <span>{{ row.tjranking1 }}</span>
            <img
              :src="require('../../../assets/top4.png')"
              alt=""
            >
          </div>
        </template>
        <template
          slot="tjaction2"
          slot-scope="{ row }"
        >
          <div v-if="row.tjranking2 === 1">
            <img
              :src="require('../../../assets/top1.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.tjranking2 === 2">
            <img
              :src="require('../../../assets/top2.png')"
              alt=""
            >
          </div>
          <div v-else-if="row.tjranking2 === 3">
            <img
              :src="require('../../../assets/top3.png')"
              alt=""
            >
          </div>
          <div
            v-else-if="row.tjname2"
            class="mclast"
          >
            <span>{{ row.tjranking2 }}</span>
            <img
              :src="require('../../../assets/top4.png')"
              alt=""
            >
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
  import { merge } from 'lodash';
  export default {
    data () {
      return {
        columns: [
          {
            title: '外商投资合作项目',
            align: 'center',
            children: [
              {
                title: '项目总量',
                align: 'center',
                children: [
                  {
                    title: '名次',
                    id: 'wsid1',
                    align: 'center',
                    key: 'wsranking1',
                    slot: 'wsaction1',
                    width: 80,
                  },
                  {
                    title: '名称',
                    id: 'wsid1',
                    key: 'wsname1',
                    align: 'left',
                  },
                  {
                    title: '数量',
                    id: 'wsid1',
                    key: 'wsnum1',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
              {
                title: '已推送项目',
                align: 'center',
                children: [
                  {
                    title: '名次',
                    id: 'wsid2',
                    align: 'center',
                    key: 'wsranking2',
                    slot: 'wsaction2',
                    width: 80,
                  },
                  {
                    title: '名称',
                    id: 'wsid2',
                    key: 'wsname2',
                    align: 'left',
                  },
                  {
                    title: '数量',
                    id: 'wsid2',
                    key: 'wsnum2',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
            ],
          },
          {
            title: '各市对外推介项目',
            align: 'center',
            children: [
              {
                title: '项目总量',
                align: 'center',
                children: [
                  {
                    id: 'tjid1',
                    title: '名次',
                    width: 80,
                    align: 'center',
                    key: 'tjranking1',
                    slot: 'tjaction1',
                  },
                  {
                    id: 'tjid1',
                    title: '名称',
                    key: 'tjname1',
                    align: 'left',
                  },
                  {
                    title: '数量',
                    id: 'tjid1',
                    key: 'tjnum1',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
              {
                title: '已推送项目',
                align: 'center',
                children: [
                  {
                    id: 'tjid2',
                    title: '名次',
                    width: 80,
                    align: 'center',
                    key: 'tjranking2',
                    slot: 'tjaction2',
                  },
                  {
                    title: '名称',
                    id: 'tjid2',
                    key: 'tjname2',
                    align: 'left',
                  },
                  {
                    title: '数量',
                    id: 'tjid2',
                    key: 'tjnum2',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
            ],
          },
        ],
        columns1: [
          {
            title: '外商投资合作项目',
            align: 'center',
            children: [
              {
                title: '项目总量',
                align: 'center',
                children: [
                  {
                    title: '名次',
                    id: 'wsid1',
                    align: 'center',
                    key: 'wsranking1',
                    slot: 'wsaction1',
                    width: 80,
                  },
                  {
                    title: '名称',
                    id: 'wsid1',
                    key: 'wsname1',
                    align: 'left',
                    width: 140,
                  },
                  {
                    title: '数量',
                    id: 'wsid1',
                    key: 'wsnum1',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
              {
                title: '已推送项目',
                align: 'center',
                children: [
                  {
                    title: '名次',
                    id: 'wsid2',
                    align: 'center',
                    key: 'wsranking2',
                    slot: 'wsaction2',
                    width: 80,
                  },
                  {
                    title: '名称',
                    id: 'wsid2',
                    key: 'wsname2',
                    align: 'left',
                    width: 140,
                  },
                  {
                    title: '数量',
                    id: 'wsid2',
                    key: 'wsnum2',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
            ],
          },
        ],
        columns2: [
          {
            title: '各市对外推介项目',
            align: 'center',
            children: [
              {
                title: '项目总量',
                align: 'center',
                children: [
                  {
                    id: 'tjid1',
                    title: '名次',
                    width: 80,
                    align: 'center',
                    key: 'tjranking1',
                    slot: 'tjaction1',
                  },
                  {
                    id: 'tjid1',
                    title: '名称',
                    key: 'tjname1',
                    align: 'left',
                    width: 100,
                  },
                  {
                    title: '数量',
                    id: 'tjid1',
                    key: 'tjnum1',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
              {
                title: '已推送项目',
                align: 'center',
                children: [
                  {
                    id: 'tjid2',
                    title: '名次',
                    width: 80,
                    align: 'center',
                    key: 'tjranking2',
                    slot: 'tjaction2',
                  },
                  {
                    title: '名称',
                    id: 'tjid2',
                    key: 'tjname2',
                    align: 'left',
                    width: 100,
                  },
                  {
                    title: '数量',
                    id: 'tjid2',
                    key: 'tjnum2',
                    align: 'center',
                    width: 80,
                  },
                ],
              },
            ],
          },
        ],
        datas: [],
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 重点推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        userGroupTree: [],
        monStart: '',
        monEnd: '',
        tjdata1: [],
        tjdata2: [],
        wsdata1: [],
        wsdata2: [],
        loading: false,
        userGroupTreeList: [],
        userGroupTreeList2: [],
        isXs: false,
      };
    },
    computed: {
      changeData () {
        const { tjdata1, tjdata2, wsdata1, wsdata2 } = this;
        return { tjdata1, tjdata2, wsdata1, wsdata2 };
      },
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
      changeData: function (val) {
        // console.log('val', val, merge(val.tjdata1, val.tjdata2, val.wsdata1, val.wsdata2));
        setTimeout(() => {
          this.datas = merge(val.tjdata1, val.tjdata2, val.wsdata1, val.wsdata2);
          this.loading = false;
        }, 800);
      },
      tjdata1 (val) {
        if (val.length === this.userGroupTreeList.length) {
          console.log('val1', val);

          let tjprescore1 = 0;// 预定义分数
          let tjranking1 = 0;// 排名
          let tjsame1 = 0; // 是否相同

          this.tjdata1.forEach((item, index) => {
            if (item.tjnum1 === tjprescore1) {
              item.tjranking1 = tjranking1;
              tjsame1++;
            } else {
              tjranking1 = tjranking1 + tjsame1;
              tjranking1++;
              tjprescore1 = item.tjnum1;
              tjsame1 = 0;
              item.tjranking1 = tjranking1;
            }
          })
        }
      },
      tjdata2 (val) {
        if (val.length === this.userGroupTreeList.length) {
          console.log('val2', val);
          let tjprescore2 = 0;// 预定义分数
          let tjranking2 = 0;// 排名
          let tjsame2 = 0; // 是否相同

          this.tjdata2.forEach((item, index) => {
            if (item.tjnum2 === tjprescore2) {
              item.tjranking2 = tjranking2;
              tjsame2++;
            } else {
              tjranking2 = tjranking2 + tjsame2;
              tjranking2++;
              tjprescore2 = item.tjnum2;
              tjsame2 = 0;
              item.tjranking2 = tjranking2;
            }
          })
        }
      },
      wsdata1 (val) {
        // if (val.length === this.userGroupTreeList2.length) {
        console.log('val2', val);
        let wsprescore1 = 0;// 预定义分数
        let wsranking1 = 0;// 排名
        let wssame1 = 0; // 是否相同

        this.wsdata1.forEach((item, index) => {
          if (item.wsnum1 === wsprescore1) {
            item.wsranking1 = wsranking1;
            wssame1++;
          } else {
            wsranking1 = wsranking1 + wssame1;
            wsranking1++;
            wsprescore1 = item.wsnum1;
            wssame1 = 0;
            item.wsranking1 = wsranking1;
          }
        })
      //  }
      },
      wsdata2 (val) {
        // if (val.length === this.userGroupTreeList2.length) {
        console.log('val2', val);
        let wsprescore2 = 0;// 预定义分数
        let wsranking2 = 0;// 排名
        let wssame2 = 0; // 是否相同

        this.wsdata2.forEach((item, index) => {
          if (item.wsnum2 === wsprescore2) {
            item.wsranking2 = wsranking2;
            wssame2++;
          } else {
            wsranking2 = wsranking2 + wssame2;
            wsranking2++;
            wsprescore2 = item.wsnum2;
            wssame2 = 0;
            item.wsranking2 = wsranking2;
          }
        })
      //  }
      },
    },
    async mounted () {
      this.loading = true;
      this.datas = [];
      var currentdate1 = this.$moment().startOf('month'); // 上个月初
      this.monStart = this.$moment(currentdate1).format('YYYY-MM-DD');
      var currentdate2 = this.$moment(new Date())
        .startOf('month')
        .subtract('month', -1);
      this.monEnd = this.$moment(currentdate2).format('YYYY-MM-DD');
      // 市区
      await this.getUserGroupTree();
    },
    methods: {
      async getUserGroupTree () {
        const res = await this.$axios.get('/dictionaries/roles');
        const resData = res.data.data;
        resData.map((val) => {
          // 推送市商务（投促）部门为父级
          if (val.value === 'ORG_TUISONGYUANQU') {
            const userGroupTree = this.treeData(resData, val.id);
            userGroupTree.sort((a, b) => {
              return a.weight - b.weight;
            });
            this.userGroupTreeList = userGroupTree
            console.log('userGroupTree', userGroupTree);
            this.gettotal1(this.tjFormId, userGroupTree);
          // 投资促进中心为父级
          } else if (val.value === 'ORG_TZCJZX') {
            this.parentOrgId = val.id;
            const userGroupTree2 = this.treeData2(resData, val.id);
            userGroupTree2.sort((a, b) => {
              return a.weight - b.weight;
            });
            const list = [];
            userGroupTree2.map((val) => {
              list.push(val);
              if (val.children.length) {
                val.children.map((vam) => {
                  list.push(vam);
                });
              }
            });

            this.userGroupTreeList2 = list
            this.gettotal2(this.wsFormId, list);
          }
        });
      },
      async gettotal1 (formId, userGroupTree) {
        userGroupTree.map(async (val, inx) => {
          const res = await this.$axios.post('/reports/statis/params/count', {
            formIds: formId,
            orgIdList: [val.id],
            // statusList: ',0',
          });
          // const res2 = await this.$axios.post('/reports/statis/params/count', { params: { formId, orgIdList: val.id, _createdFrom: this.monStart, _createdTo: this.monEnd } })

          const res2 = await this.$axios.post('/reports/statis/params/count', {
            formIds: formId,
            orgIdList: [val.id],
            params: { projectStatus2: 'VALID' },
          });

          this.tjdata1.push({
            tjid1: val.id,
            tjname1: val.name,
            tjnum1: res.data.data[0].second,
            ranking: 0,
          });
          this.tjdata1.sort((b, a) => {
            return a.tjnum1 - b.tjnum1;
          });
          this.tjdata2.push({
            tjid2: val.id,
            tjname2: val.name,
            tjnum2: res2.data.data[0].second,
          });
          this.tjdata2.sort((b, a) => {
            return a.tjnum2 - b.tjnum2;
          });
        });
        console.log('this.tjdata1', this.tjdata1);
        console.log('this.tjdata2', this.tjdata2);
      },
      // async gettotal2 (formId, userGroupTree) {
      //   userGroupTree.map(async (val, inx) => {
      //     const res = await this.$axios.post('/reports/statis/params/count', {
      //       formIds: formId,
      //       orgIdList: [val.id],
      //       statusList: '5,10,0',
      //     });
      //     // const res2 = await this.$axios.post('/reports/statis/params/count', { params: { formId, orgIdList: val.id, _createdFrom: this.monStart, _createdTo: this.monEnd } })
      //     const res2 = await this.$axios.post('/reports/statis/params/count', {
      //       formIds: formId,
      //       orgIdList: [val.id],
      //       params: { projectStatus2: 'VALID' },
      //     });
      //     this.wsdata1.push({
      //       wsid1: val.id,
      //       wsname1: val.name,
      //       wsnum1: res.data.data[0].second,
      //     });
      //     this.wsdata1.sort((b, a) => {
      //       return a.wsnum1 - b.wsnum1;
      //     });
      //     this.wsdata2.push({
      //       wsid2: val.id,
      //       wsname2: val.name,
      //       wsnum2: res2.data.data[0].second,
      //     });
      //     this.wsdata2.sort((b, a) => {
      //       return a.wsnum2 - b.wsnum2;
      //     });
      //   });
      //   console.log('this.wsdata1', this.wsdata1);
      //   console.log('this.wsdata2', this.wsdata2);
      // },
      async gettotal2 (formId) {
        const res = await this.$axios.post('/reports/countByUserid', {
          formIds: formId,
          statusList: '5,10,0',
        });
        const res2 = await this.$axios.post('/reports/countByUserid', {
          formIds: formId,
          params: { projectStatus2: 'VALID' },
        });
        const wsdata1 = res.data.data
        wsdata1.map((val, inx) => {
          this.wsdata1.push({
            wsid1: inx,
            wsname1: val.name,
            wsnum1: val.cnt,
          });
        })

        const wsdata2 = res.data.data
        wsdata2.map((val, inx) => {
          this.wsdata2.push({
            wsid2: inx,
            wsname2: val.name,
            wsnum2: val.cnt,
          });
        })
        // this.wsdata1.sort((b, a) => {
        //   return a.wsnum1 - b.wsnum1;
        // });
        // this.wsdata2.push({
        //   wsid2: val.id,
        //   wsname2: val.name,
        //   wsnum2: res2.data.data[0].second,
        // });
        // this.wsdata2.sort((b, a) => {
        //   return a.wsnum2 - b.wsnum2;
        // });
        console.log('this.wsdata1', this.wsdata1);
        console.log('this.wsdata2', this.wsdata2);
      },
      treeData (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData));
        return cloneData.filter((father) => {
          return father.parentId + '' === id + '';
        });
      },
      treeData2 (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData));
        return cloneData.filter((father) => {
          // 园区父级id
          if (father.id === '8532ec90-3601-4d52-8c7c-c1cd99a11aa8') {
            return;
          }
          const branchArr = cloneData.filter(
            (child) =>
              father.id === child.parentId && !child.value.includes('ORG_37'),
          );
          branchArr.length > 0
            ? (father.children = branchArr)
            : (father.children = []);
          return father.parentId + '' === id + '';
        });
      },
    },
  };
</script>
<style scoped lang="scss">
.mclast {
  position: relative;
  span {
    width: 14px;
    left: 14px;
    font-size: 10px;
    position: absolute;
  }
}
</style>
